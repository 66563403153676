import * as React from 'react';
import Frame from '../components/frame'

const GalleryPage = () => {
	return (
		<Frame>
			<p>これがchildrenとして渡される</p>
		</Frame>
	)
}

export default GalleryPage